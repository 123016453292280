import type { RespOrder, Result } from './types'
import { syncFormPost } from '../../base/syncForm'

const BaseUrl = process.env.VUE_APP_BASE_API
// 获取订单详情
export const getOrderInfo = (orderId: string, status: number): Promise<RespOrder> => fetch(`${BaseUrl}/findPayTypeDetails?key=${orderId}&status=${status}`).then((res) => res.json())
// 获取订单结果
export const getOrderResult = (orderId: string): Promise<Result> => fetch(`${BaseUrl}/findOrderStatus?key=${orderId}`).then((res) => res.json())

// 同步提交信息
export const submitOrder = (data: RespOrder) => syncFormPost('/SelectToBank', data)

// 校验支付方式json提交
const headers = {
  'Content-Type': 'application/json'
}
interface ValidatePayTypeParams {
  cardNo: string,
  cardType: string
}
interface Message {
  msg: string
  status: number
}
// 校验支付方式
export const validatePayType = (data: ValidatePayTypeParams): Promise<Message> => fetch(`${BaseUrl}/validatePayType`, { method: 'POST', headers, body: JSON.stringify(data) }).then(res => res.json())